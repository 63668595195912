import React, { useState, useEffect } from "react";
import { Row, Col, Container } from "react-bootstrap";
import fireDb from "../firebase";

export const Footer = () => {
  var [eventObjects, setEventObjects] = useState({});
  const currentPath = window.location.pathname;
  const pathContainsString = currentPath.includes('admin');

  useEffect(() => {
    fireDb
      .database()
      .ref()
      .child("Members")
      .orderByChild("filterKey")
      .equalTo("Partner")
      .limitToFirst(8)
      .on("value", (snapshot) => {
        if (snapshot.val() != null)
          setEventObjects({
            ...snapshot.val(),
          });
      });
  }, []);
  return (
    <>
    { !pathContainsString ? 
    <div className="footer">
      <br />
      <Row>
        <Col xs={12} md={4}>
          <h1>Partners</h1>
        </Col>
      </Row>
      <Container>
        <Row className="footer-partners">
          {Object.keys(eventObjects).map((id) => {
            return (
              <Col xs={6} sm={4} md={2} key={id}>
                <div className="footer-partners-section">
                  <a href={eventObjects[id]?.linkToMember} target="_blank">
                    <img src={eventObjects[id]?.imageSource} alt="" />
                  </a>
                </div>
              </Col>
            );
          })}
        </Row>
      </Container>
    </div> : null}
     </>
  );
};
