import * as firebase from "firebase";
import "firebase/auth";
import "firebase/storage";

var firebaseConfig = {
  apiKey: "AIzaSyAYa1zOW0jX2XjYuJnJaotCz0ZSFH6f_Dc",
  authDomain: "english-universities-sport.firebaseapp.com",
  databaseURL: "https://english-universities-sport-default-rtdb.firebaseio.com",
  projectId: "english-universities-sport",
  storageBucket: "english-universities-sport.appspot.com",
  messagingSenderId: "463714414233",
  appId: "1:463714414233:web:eb063adf75a5b274a8d208",
  measurementId: "G-83N1PGZZ07",
};

// Initialize Firebase
var fireDb = firebase.initializeApp(firebaseConfig);

export default fireDb;
