import React, { useState, useEffect } from "react";
import fireDb from "../firebase";
import { FaPencilAlt, FaTrash } from "react-icons/fa";
import { Col, Row } from "react-bootstrap";
import SignOut from "../shared/SignOut";
import HeroHeaderAdminForm from "./HeroHeaderAdminForm";

export const HeroHeaderAdminPage = () => {
  var [headingData, setHeadingData] = useState({});

  var [currentId, setCurrentId] = useState("");

  useEffect(() => {
    fireDb
      .database()
      .ref()
      .child("HeroHeader")
      .on("value", (snapshot) => {
        if (snapshot.val() != null)
          setHeadingData({
            ...snapshot.val(),
          });
      });
  }, []);

  const addOrEdit = (obj) => {
    if (currentId === "")
      fireDb
        .database()
        .ref()
        .child("HeroHeader")
        .push(obj, (err) => {
          if (err) console.log();
          else setCurrentId("");
        });
    else
      fireDb
        .database()
        .ref()
        .child(`HeroHeader/${currentId}`)
        .set(obj, (err) => {
          if (err) console.log(err);
          else setCurrentId("");
        });
  };

  const onDelete = (id) => {
    if (window.confirm("Are you sure to delete this record")) {
      fireDb
        .database()
        .ref()
        .child(`HeroHeader/${id}`)
        .remove((err) => {
          if (err) console.log(err);
          else setCurrentId("");
        });
    }
  };

  return (
    <div style={{ height: "auto", backgroundColor: "white" }}>
      <div style={{ height: "100px" }}></div>
      <br />
      <SignOut />
      <Row>
        <Col xs={2} md={4}></Col>
        <Col xs={8} md={4}>
          <h1>Pages Heading Component Admin Section</h1>
        </Col>
      </Row>
      <br />
      <Row>
        <Col xs={4}></Col>
        <Col xs={4} className="admin-form">
          <h3>Shop Submission Form</h3>
          <HeroHeaderAdminForm {...{ addOrEdit, currentId, headingData }} />
        </Col>
      </Row>
      <Row>
        <Col xs={12} className="admin-list">
          <h3>List of Pages Heading Component data</h3>
          <table>
            <thead>
              <tr>
                <th style={{ minWidth: "120px" }}>Title</th>
                <th style={{ minWidth: "200px" }}>Subheading</th>
                <th style={{ minWidth: "200px" }}>Image Source</th>
                <th style={{ minWidth: "200px" }}>Key</th>

                <th>Edit/Delte</th>
              </tr>
            </thead>
            <tbody>
              {Object.keys(headingData).map((id) => {
                return (
                  <tr key={id}>
                    <td>{headingData[id].title}</td>
                    <td
                      style={{
                        minWidth: "300px",
                        maxWidth: "300px",
                        overflow: "scroll",
                      }}
                    >
                      {headingData[id].bodyText}
                    </td>
                    <td
                      style={{
                        minWidth: "300px",
                        maxWidth: "300px",
                        overflow: "scroll",
                      }}
                    >
                      {headingData[id].imageSource}
                    </td>
                    <td
                      style={{
                        minWidth: "300px",
                        maxWidth: "300px",
                        overflow: "scroll",
                      }}
                    >
                      {headingData[id].key}
                    </td>

                    <td>
                      <div
                        className="btn text-primary"
                        onClick={() => {
                          setCurrentId(id);
                        }}
                      >
                        <FaPencilAlt />
                      </div>
                      <div
                        className="btn text-danger"
                        onClick={() => {
                          onDelete(id);
                        }}
                      >
                        <FaTrash />
                      </div>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </Col>
      </Row>
    </div>
  );
};

export default HeroHeaderAdminPage;
