import React, { useState, useEffect } from "react";
import { Carousel } from "react-bootstrap";
import { BiRightArrowCircle } from "react-icons/bi";
import { AiOutlineCaretDown } from "react-icons/ai";
import fireDb from "../../firebase";
import useIsMobile from '../../useIsMobile';


export const CarouselComponent = ({
  imageSrc,
  Heading,
  ButtonText,
  ButtonLink,
}) => {
  var [eventObjects, setEventObjects] = useState({});
  const isMobile = useIsMobile();
  useEffect(() => {
    fireDb
      .database()
      .ref()
      .child("HomepageHeroSlider")
      .on("value", (snapshot) => {
        if (snapshot.val() != null)
          setEventObjects({
            ...snapshot.val(),
          });
      });
  }, []);
  return (
    <>
      <Carousel fade>
        {Object.keys(eventObjects).map((id) => {
          return (
            <Carousel.Item interval={1500000} key={id}>
              <img
                className="d-block w-100  carousel-image"
                src={isMobile ? eventObjects[id]?.mobimageSrc || eventObjects[id]?.imageSrc : eventObjects[id]?.imageSrc}
                alt="First slide"
              />
              <div className="layer"></div>

              <Carousel.Caption className="carousel-text">
                <h3>{eventObjects[id]?.Heading}</h3>
                <div className="carousel-btn">
                  <a href={eventObjects[id]?.ButtonLink}>
                    <span>{eventObjects[id]?.ButtonText}</span>

                    <div className="button-icon">
                      <BiRightArrowCircle color="maroon" />
                    </div>
                  </a>
                </div>
              </Carousel.Caption>
            </Carousel.Item>
          );
        })}
      </Carousel>

      <div className="hero-banner-floating-arrow-caro">
        <AiOutlineCaretDown size="2.5rem" color="white" />
      </div>
    </>
  );
};
