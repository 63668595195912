import React from "react";
import { AiOutlineCaretDown } from "react-icons/ai";

const HeroBannerHeader = ({ image, title, bodyText }) => {
  // let url = window.location.pathname;

  return (
    <>
      <div className="staff-and-coaching-hero">
        <img className="staff-and-coaching-img" src={image} />
        <div className="staff-and-coaching-hero-text">
          {title && <h1>{title}</h1>}
          {bodyText && <span>{bodyText}</span>}
        </div>
        <div className="layer-staff"></div>
        <div className="hero-banner-floating-arrow">
          <AiOutlineCaretDown size="2.5rem" color="white" />
        </div>
      </div>
    </>
  );
};

export default HeroBannerHeader;
