import React, { useState, useEffect } from "react";
import { Row, Col, Card } from "react-bootstrap";
import { BsArrowRight } from "react-icons/bs";
import fireDb from "../../firebase";

export const News = () => {
  var [eventObjects, setEventObjects] = useState({});

  useEffect(() => {
    fireDb
      .database()
      .ref()
      .child("News")
      .limitToLast(4)
      .on("value", (snapshot) => {
        if (snapshot.val() != null)
          setEventObjects({
            ...snapshot.val(),
          });
      });
  }, []);

  const reversedKeys = Object.keys(eventObjects).reverse();

  const initialFieldValues = {
    SubscribersName: "",
    SubscribersEmail: "",
  };

  var [values, setValues] = useState(initialFieldValues);

  const addToDb = (obj) => {
    fireDb
      .database()
      .ref()
      .child("Subscribers")
      .push(obj, (err) => {
        if (err) console.log();
      });
  };

  const handleInputChange = (e) => {
    var { name, value } = e.target;
    setValues({
      ...values,
      [name]: value,
    });
  };

  const handleFormSubmit = (e) => {
    e.preventDefault();
    addToDb(values);
  };

  return (
    <>
      <Row>
        <div className="box-news">
          <h1>Latest News</h1>
        </div>
      </Row>

      <Row className="article-container">
        {Object.keys(reversedKeys).map((id) => {
          return (
            <Col xs={9} md={6} lg={4} xl={3} key={reversedKeys[id]}>
              <a href={"/news/" + [reversedKeys[id]]}>
                <Card
                  style={{
                    width: "240px",
                    height: "auto",
                    marginBottom: "10px",
                  }}
                >
                  <Card.Img
                    variant="top"
                    src={eventObjects[reversedKeys[id]]?.imageSrc}
                    className="card-img-mob"
                  />
                  <Card.Body>
                    <Card.Title
                      style={{
                        textAlign: "left",
                        fontSize: "1.2rem",
                        lineHeight: "1.2em",
                        height: "3.6em",
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                        color: "maroon",
                        fontWeight: "700",
                      }}
                    >
                      {/* <span style={{ color: "maroon", fontWeight: "700" }}>
                        Latest News
                      </span> */}
                      {/* <br /> */}
                      {eventObjects[reversedKeys[id]]?.title}
                    </Card.Title>
                  </Card.Body>
                </Card>
              </a>
            </Col>
          );
        })}
      </Row>
      <Row>
        <Col xs={0} md={4} lg={3}></Col>
        <Col xs={0} md={4} lg={3}></Col>
        <Col xs={6} md={4} lg={3}></Col>
        <Col xs={6} md={4} lg={3}>
          <div className="view-all-link">
            <a href="/news">
              <span>
                View all <BsArrowRight color="maroon" size="2rem" />
              </span>
            </a>
          </div>
        </Col>
      </Row>
      <Row>
        <Col xs={12} className="subscribe-box">
          <h4>Stay Up To Date With The Latest News</h4>
          <form autoComplete="off" onSubmit={handleFormSubmit}>
            <input
              type="text"
              placeholder="Subscribers Name"
              name="SubscribersName"
              value={values.SubscribersName}
              onChange={handleInputChange}
            />
            <input
              type="text"
              placeholder="Subscribers Email"
              name="SubscribersEmail"
              value={values.SubscribersEmail}
              onChange={handleInputChange}
            />
            <button>Subscribe</button>
          </form>
        </Col>
      </Row>
      <br />
      <br />
      <br />
    </>
  );
};
