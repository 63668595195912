import React from "react";
import { BsFillArrowDownSquareFill } from "react-icons/bs";

export const DownIcon = () => {
  return (
    <div className="arrow-down d-none d-md-block">
      <BsFillArrowDownSquareFill size="5rem" color="black" />
    </div>
  );
};
