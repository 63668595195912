import React, { useState, useEffect } from "react";
import fireDb from "../firebase";
import { FaPencilAlt, FaTrash } from "react-icons/fa";
import { Col, Row } from "react-bootstrap";
import SquadAdminForm from "./SqaudAdminForm";
import SignOut from "../shared/SignOut";

export const SquadAdminPage = () => {
  var [eventObjects, setEventObjects] = useState({});

  var [currentId, setCurrentId] = useState("");

  useEffect(() => {
    fireDb
      .database()
      .ref()
      .child("Squads")
      .on("value", (snapshot) => {
        if (snapshot.val() != null)
          setEventObjects({
            ...snapshot.val(),
          });
      });
  }, []);

  const addOrEdit = (obj) => {
    if (currentId === "")
      fireDb
        .database()
        .ref()
        .child("Squads")
        .push(obj, (err) => {
          if (err) console.log();
          else setCurrentId("");
        });
    else
      fireDb
        .database()
        .ref()
        .child(`Squads/${currentId}`)
        .set(obj, (err) => {
          if (err) console.log(err);
          else setCurrentId("");
        });
  };

  const onDelete = (id) => {
    if (window.confirm("Are you sure to delete this record")) {
      fireDb
        .database()
        .ref()
        .child(`Squads/${id}`)
        .remove((err) => {
          if (err) console.log(err);
          else setCurrentId("");
        });
    }
  };

  return (
    <div style={{ height: "auto", backgroundColor: "white" }}>
      <div style={{ height: "100px" }}></div>
      <br />
      <SignOut />
      <Row>
        <Col xs={4}></Col>
        <Col xs={4}>
          <h1>Squads Admin Section</h1>
        </Col>
      </Row>
      <br />
      <Row>
        <Col xs={4}></Col>
        <Col xs={4} className="admin-form">
          <h3>Squads Submission Form</h3>
          <SquadAdminForm {...{ addOrEdit, currentId, eventObjects }} />
        </Col>
      </Row>
      <Row>
        <Col xs={12} className="admin-list">
          <h3>List of Squads</h3>
          <table>
            <thead>
              <tr>
                <th style={{ minWidth: "120px" }}>Sport Name</th>
                <th style={{ minWidth: "200px" }}>Body Text</th>
                <th style={{ minWidth: "200px" }}>Squad List</th>
                <th style={{ maxWidth: "400px" }}>Image Source</th>
                <th>Edit/Delte</th>
              </tr>
            </thead>
            <tbody>
              {Object.keys(eventObjects).map((id) => {
                return (
                  <tr key={id}>
                    <td>{eventObjects[id].titleOfSport}</td>
                    <td>{eventObjects[id].bodyText}</td>
                    <td>{eventObjects[id].squadList}</td>

                    <td style={{ maxWidth: "400px", overflow: "scroll" }}>
                      {eventObjects[id].imageSrc}
                    </td>

                    <td>
                      <div
                        className="btn text-primary"
                        onClick={() => {
                          setCurrentId(id);
                        }}
                      >
                        <FaPencilAlt />
                      </div>
                      <div
                        className="btn text-danger"
                        onClick={() => {
                          onDelete(id);
                        }}
                      >
                        <FaTrash />
                      </div>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </Col>
      </Row>
    </div>
  );
};

export default SquadAdminPage;
