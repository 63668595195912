import React, { useState, useEffect } from "react";
import HeroBannerHeader from "../shared/HeroBannerHeader";
import fireDb from "../firebase";
import { Col, Container, Row } from "react-bootstrap";

const GetInvolved = () => {
  var [eventObjects, setEventObjects] = useState({});
  var [headingData, setHeadingData] = useState({});

  useEffect(() => {
    fireDb
      .database()
      .ref()
      .child("HeroHeader")
      .orderByChild("key")
      .equalTo("GetInvolved")
      .on("value", (snapshot) => {
        if (snapshot.val() != null)
          setHeadingData({
            ...snapshot.val(),
          });
      });
  }, []);

  useEffect(() => {
    fireDb
      .database()
      .ref()
      .child("GetInvolved")
      .on("value", (snapshot) => {
        if (snapshot.val() != null)
          setEventObjects({
            ...snapshot.val(),
          });
      });
  }, []);
  return (
    <>
      {Object.keys(headingData).map((id) => {
        return (
          <HeroBannerHeader
            title={headingData[id].title}
            image={headingData[id].imageSource}
          />
        );
      })}
      <Row className="get-invovled-page-text" style={{ marginRight: "0px" }}>
      {Object.keys(headingData).map((id) => {
        return (
      <div className="mob-banner-title"><h1>{headingData[id].title}</h1></div>
      );
    })}
        <Col lg={3}></Col>
        <Col lg={6} className="get-invovled-page-text-span">
          <span>
            Getting involved in sport at university provides a platform to build
            friendships, develop skills and stay active whilst at university.
            Many universities offer on campus sports via participation
            programmes or play in the BUCS leagues. If playing competitive isn’t
            you thing there is the opportunity to volunteer within your
            university club.
            <br />
            <br />
            EUS is a voluntary led organisation and has an number of
            opportunities each year for individuals who wish contribute to the
            wide higher education game.
          </span>
        </Col>
        <Col lg={3}></Col>
      </Row>
      <Container>
        <Row>
          {Object.keys(eventObjects).map((id) => {
            return (
              <Col className="get-involved-buttons" key={id}>
                <button className="button-19">
                  <a href={"mailto:" + eventObjects[id]?.emailButtonLink}>
                    {eventObjects[id]?.buttonTitle}
                  </a>
                </button>
              </Col>
            );
          })}
        </Row>
      </Container>
    </>
  );
};

export default GetInvolved;
