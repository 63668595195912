import React from "react";
import fireDb from "../firebase";
import { Container, Row, Col } from "react-bootstrap";

const SignOut = () => {
  return (
    <Container>
      <Row className="admin-home-logging">
        <Col xs={3}></Col>

        <Col xs={3}>
          <button onClick={() => fireDb.auth().signOut()}>Sign out</button>
        </Col>
        <Col xs={3}>
          <a href="/admin">
            <button>Admin Hub</button>
          </a>
        </Col>
        <Col xs={3}></Col>
      </Row>
    </Container>
  );
};

export default SignOut;
