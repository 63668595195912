import { Row, Col } from "react-bootstrap";

export const all = () => {
  return (
  <Row className="admin-home-nav">
    <Col xs={6} md={4}>
      <a href="/coaching-admin">
        <button>Coaches</button>
      </a>
    </Col>
    <Col xs={6} md={4}>
      <a href="/committee-admin">
        <button>Committee</button>
      </a>
    </Col>
    <Col xs={6} md={4}>
      <a href="/comp-admin">
        <button>Competitions</button>
      </a>
    </Col>
    <Col xs={6} md={4}>
      <a href="/getInvolved-admin">
        <button>Get Involved</button>
      </a>
    </Col>
    <Col xs={6} md={4}>
      <button>
        <a href="/members-admin">Partners</a>
      </button>
    </Col>
    <Col xs={6} md={4}>
      <a href="/patron-admin">
        <button>Patrons</button>
      </a>
    </Col>
    <Col xs={6} md={4}>
      <a href="/news-admin">
        <button>News</button>
      </a>
    </Col>
    <Col xs={6} md={4}>
      <a href="/shop-admin">
        <button>Shop</button>
      </a>
    </Col>
    <Col xs={6} md={4}>
      <a href="/squad-admin">
        <button>Squads</button>
      </a>
    </Col>
    <Col xs={6} md={4}>
      <a href="/student-test-admin">
        <button>Testimonials</button>
      </a>
    </Col>
    <Col xs={6} md={4}>
      <a href="/vacancy-admin">
        <button>Vacancy</button>
      </a>
    </Col>
    <Col xs={6} md={4}>
      <a href="/upload-files">
        <button>Upload File</button>
      </a>
    </Col>
    <Col xs={6} md={4}>
      <a href="/hp-caro-admin">
        <button>Hero Carousel Image</button>
      </a>
    </Col>
    <Col xs={6} md={4}>
      <a href="/aboutus-admin">
        <button>About Us</button>
      </a>
    </Col>
    <Col xs={6} md={4}>
      <a href="/pages-heading-component">
        <button>Pages Heading component</button>
      </a>
    </Col>
    <Col xs={6} md={4}>
      <a href="/home-get-involved-box">
        <button>Homepage Get Inv box</button>
      </a>
    </Col>
    <Col xs={6} md={4}>
      <a href="/bucs-admin">
        <button>Bucs</button>
      </a>
    </Col>
    <Col xs={6} md={4}>
      <a href="/squads-paralax-admin">
        <button>Squads Paralax Section</button>
      </a>
    </Col>
    <Col xs={6} md={4}>
      <a href="/News-item-image-admin">
        <button>News Item Image Header</button>
      </a>
    </Col>
  </Row> )};