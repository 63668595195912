import React, { useEffect, useState } from "react";
import HeroBannerHeader from "../shared/HeroBannerHeader";
import image from "../images/getInvolved.webp";
import { Row, Col, Container } from "react-bootstrap";
import { SectionTitle } from "../shared/SectionTitle";
import fireDb from "../firebase";
import SignOut from "../shared/SignOut";
import logo from "../images/eus-logo1.webp";
import './styles/AdminHome.css';
import { all } from "./NewDisplay/AllAdminPages";
import { UtilityAdminPage } from "./NewDisplay/UtilityAdminPages";
import PageContent from "./NewDisplay/PageContent";


const AdminHome = () => {
  const [selectedPage, setSelectedPage] = useState('');
  const [selectedPageMsg, setSelectedPageMsg] = useState(false);
  const [selectedSubPage, setSelectedSubPage] = useState('');
  const [selectedContent, setSelectedContent] = useState('filterPages');

  useEffect(() => {
    if (selectedPage === '' || (selectedPage === 'eus' && selectedSubPage === '')) {
      setSelectedPageMsg(true);
    } else {
      setSelectedPageMsg(false);
    }
  }, [selectedPage, selectedSubPage]); 


  const handlePageChange = (event) => {
    setSelectedPage(event.target.value);
    if (event.target.value !== 'eus') {
      setSelectedSubPage('');
    }
  };
  const handleSubPageChange = (event) => {
    setSelectedSubPage(event.target.value);
  };

  const renderSubDropdown = () => {
    if (selectedPage === 'eus') {
      return (
        <div>
          <select value={selectedSubPage} onChange={handleSubPageChange}>
            <option value="">Select a Sub-Page...</option>
            <option value="aboutus">About Us</option>
            <option value="staff">Staff</option>
            <option value="partners">Partners</option>
            <option value="patrons">Patrons</option>
            <option value="getinvolved">Get Involved</option>
          </select>
        </div>
      );
    }
  };

  const handleClick = (props) => {
    setSelectedContent(props);
  }
  return (
    <div className="admin-container">
      <div className="sidebar">
        <img src={logo} alt="Logo" className="sidebar-logo" />
        <div className="link-container">
          <div onClick={() => handleClick('filterPages')} className="sidebar-link">Filter pages</div>
          <div onClick={() => handleClick('all')} className="sidebar-link">All</div>
          <div onClick={() => handleClick('utilities')} className="sidebar-link">Utility</div>
        </div>
  
    <button
     onClick={() => fireDb.auth().signOut()}
     className="sign-out-button"
     >
      Sign Out
    </button>
  </div>

  <div className="content-area">
  { selectedContent === 'filterPages' && (
        <Row>
        <Col sm={3}>
        <select value={selectedPage} onChange={handlePageChange} className="admin-page-selector">
          <option value="">Select a Page...</option>
          <option value="homepage">Homepage</option>
          <option value="eus">EUS</option>
          <option value="news">News</option>
          <option value="squads">Squads</option>
          <option value="shop">Shop</option>
        </select>
        {renderSubDropdown()}

        {selectedPageMsg? <div>Select a page to see its content.</div> : null }
        </Col>
        <Col sm={9}>
        <PageContent selectedPage={selectedPage} selectedSubPage={selectedSubPage} />
        </Col>
      </Row>
        )}
    { selectedContent === 'all' && (
      all()
    )}
    { selectedContent === 'utilities' && (
      UtilityAdminPage()
    )}
  </div>
    </div>
  );
};

export default AdminHome;
