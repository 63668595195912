import React from "react";
import { Row, Col } from "react-bootstrap";

const Testimonials = ({ text, author, image, linkToMember }) => {
  let url = window.location.pathname;

  return (
    <>
      <Row className="testimonial-item">
        <Col xs={12} md={3} className="testimoial-image">
          { url ? 
          <a href={linkToMember} target="_blank"><img src={image} alt="" className="student-testimonial-item-image" /></a> : 
              <img src={image} alt="" className="student-testimonial-item-image" />
            }
        </Col>
        <Col xs={12} md={8} style={{ textAlign: "left" }}>
        {url === "/about/partners" ? (<div className="testimoial-text"><span>{text}</span></div>) : <div className="testimoial-text"><span>"{text}"</span></div>}
          <br />
          <h4>{author}</h4>
        </Col>
      </Row>
    </>
  );
};

export default Testimonials;
