import React from "react";
import { Container, Image, Carousel, Row, Col } from "react-bootstrap";
import { CarouselComponent } from "./components/CarouselComponent";
import { AboutUsSection } from "./components/AboutUsSection";
import { News } from "./components/News";
import { Competitions } from "./components/Competitions";
import { DownIcon } from "../shared/DownIcon";
import { PartnersSection } from "../about/partners/PartnersSection";
import { BiRightArrowCircle } from "react-icons/bi";
import HeroNav from "./components/HeroNax";
import GetInvolved from "./components/GetInvolved";
import Bucs from "./components/Bucs";
import fireDb from "../firebase";
import MobileNavBar from "../shared/MobileNav";

export const Home = () => {
  return (
    <div>
      <CarouselComponent />
      <AboutUsSection />
      <Container>
        <Competitions />
        <GetInvolved />
        <News />
      </Container>
      <Bucs />
      <DownIcon />
      <br />
    </div>
  );
};
