import React, { useState, useEffect } from "react";
import HeroBannerHeader from "../shared/HeroBannerHeader";
import fireDb from "../firebase";
import { Row, Col } from "react-bootstrap";
import { ParallaxSection } from "../shared/ParallaxSection";

const AboutUs = () => {
  var [headingData, setHeadingData] = useState({});

  useEffect(() => {
    fireDb
      .database()
      .ref()
      .child("HeroHeader")
      .orderByChild("key")
      .equalTo("AboutUs")
      .on("value", (snapshot) => {
        if (snapshot.val() != null)
          setHeadingData({
            ...snapshot.val(),
          });
      });
  }, []);
  var [eventObjects, setEventObjects] = useState({});

  useEffect(() => {
    fireDb
      .database()
      .ref()
      .child("AboutUs")
      .on("value", (snapshot) => {
        if (snapshot.val() != null)
          setEventObjects({
            ...snapshot.val(),
          });
      });
  }, []);
  return (
    <>
      {Object.keys(headingData).map((id) => {
        return (
          <HeroBannerHeader
            title={headingData[id].title}
            image={headingData[id].imageSource}
          />
        );
      })}
      {Object.keys(eventObjects).map((id) => {
        return (
          <Row className="row1 about-us-page" key={id} style={{ marginRight: "0px" }}>
            <Col xs={0} md={1} className="about-us-page-hidden-col" ></Col>
            <Col xs={12} md={5} className="col2">
              <h1>{eventObjects[id]?.title}</h1>
              <p>{eventObjects[id]?.bodyText}</p>
            </Col>
            <Col
              xs={12}
              md={6}
              className="col1"
              style={{ paddingRight: "0px" }}
            >
              <img
                src={eventObjects[id].imageSrc}
                height="800px"
                alt=""
                srcset=""
              />
            </Col>
          </Row>
        );
      })}
    </>
  );
};

export default AboutUs;
