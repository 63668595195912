import React, { useState, useEffect } from "react";
import fireDb from "../firebase";
import { SectionTitle } from "../shared/SectionTitle";
import { Container, Row, Col } from "react-bootstrap";

const StaffCard = ({ name, position, photoUrl, email, sport }) => {
  console.log("position ", position);
  return (
    <div className="staff-card">
      <img src={photoUrl} alt={name} className="staff-photo" />
      <div className="staff-info">
        <h3 className="staff-name">{name}</h3>
        <p className="staff-position">{position}</p>
        { !sport ? 
        <p className="staff-email"><a href={`mailto:${email}`}>Contact</a></p> :
        <p className="staff-position">{sport}</p>
        }
        
      </div>
    </div>
  );
};


export const StaffAndCoaching = () => {
  var [eventObjects, setEventObjects] = useState({});
  var [coachingObjects, setCoachingObjects] = useState({});
  var [headingData, setHeadingData] = useState({});

  useEffect(() => {
    fireDb
      .database()
      .ref()
      .child("HeroHeader")
      .orderByChild("key")
      .equalTo("StaffandCoaching")
      .on("value", (snapshot) => {
        if (snapshot.val() != null)
          setHeadingData({
            ...snapshot.val(),
          });
      });
  }, []);

  useEffect(() => {
    fireDb
      .database()
      .ref()
      .child("Committee")
      .on("value", (snapshot) => {
        if (snapshot.val() != null)
          setEventObjects({
            ...snapshot.val(),
          });
      });
  }, []);

  useEffect(() => {
    fireDb
      .database()
      .ref()
      .child("Coaching")
      .on("value", (snapshot) => {
        if (snapshot.val() != null)
          setCoachingObjects({
            ...snapshot.val(),
          });
      });
  }, []);

  console.log(eventObjects);
  return (
    <div className="staff-and-coaching">
      {Object.keys(headingData).map((id) => {
        return (
          <div className="staff-and-coaching-hero">
            <img
              className="staff-and-coaching-img"
              src={headingData[id].imageSource}
              alt=""
            />
            <div className="staff-and-coaching-hero-text">
              <h1>{headingData[id].title}</h1>
            </div>
            <div className="layer-staff"></div>
          </div>
        );
      })}
      <Container>
        <SectionTitle title="Committee Members" />
        <table>
          <tr>
            <th>Name</th>
            <th>Position</th>
            <th>Email</th>
          </tr>
          {Object.keys(eventObjects).map((id) => {
            return (
              <tr key={id}>
                <td>{eventObjects[id]?.name}</td>
                <td>{eventObjects[id]?.position}</td>
                <td className="staff-email-text">{eventObjects[id]?.email}</td>
                <td className="staff-email-link"><a href={`mailto:${eventObjects[id]?.email}`}>Contact</a></td>
              </tr>
            );
          })}
        </table>
        <SectionTitle title="Coaching" />

        <table>
          <tr>
            <th>Name</th>
            <th>Sport</th>
            <th>Role</th>
          </tr>
          {Object.keys(coachingObjects).map((id) => {
            return (
              <tr key={id}>
                <td>{coachingObjects[id]?.name}</td>
                <td>{coachingObjects[id]?.sport}</td>
                <td>{coachingObjects[id]?.role}</td>
              </tr>
            );
          })}
        </table>
        <br />
        {/* <SectionTitle title="Committee Members" />

        <div className="staff-grid">
          {Object.keys(eventObjects).map((id) => {
              return (
                <StaffCard
                key={eventObjects[id]?.name}
                name={eventObjects[id]?.name}
                position={eventObjects[id]?.position}
                email={eventObjects[id]?.email}
                photoUrl="https://upload.wikimedia.org/wikipedia/en/thumb/3/3b/SpongeBob_SquarePants_character.svg/440px-SpongeBob_SquarePants_character.svg.png"
              />
              );
          })}
        </div>

        <SectionTitle title="Coaching" />
        <div className="staff-grid">
          {Object.keys(coachingObjects).map((id) => {
              return (
                <StaffCard
                key={coachingObjects[id]?.name}
                name={coachingObjects[id]?.name}
                position={coachingObjects[id]?.role}
                sport={coachingObjects[id]?.sport}
                photoUrl="https://upload.wikimedia.org/wikipedia/en/thumb/3/3b/SpongeBob_SquarePants_character.svg/440px-SpongeBob_SquarePants_character.svg.png"
              />
              );
          })}
        </div> */}

      </Container>
    </div>
  );
};
