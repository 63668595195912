import { Row, Col } from "react-bootstrap";

export const UtilityAdminPage = () => {
  return (
  <Row className="admin-home-nav">
    <Col xs={6} md={4}>
      <a href="/upload-files">
        <button>Upload File</button>
      </a>
    </Col>
  </Row> 
  )};