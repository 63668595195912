import React, { useState, useEffect } from "react";
import fireDb from "../../firebase";

import { Row, Col } from "react-bootstrap";

const GetInvolved = () => {
  var [headingData, setHeadingData] = useState({});

  useEffect(() => {
    fireDb
      .database()
      .ref()
      .child("HomepageGetInvBox")
      .on("value", (snapshot) => {
        if (snapshot.val() != null)
          setHeadingData({
            ...snapshot.val(),
          });
      });
  }, []);
  return (
    <Row style={{ marginTop: "20px" }} className="getInvolved">
      <Col xs={12}>
        {Object.keys(headingData).map((id) => {
          return (
            <>
              <div className="getInvolved-layer">
                <img src={headingData[id].imageSource} alt="" srcSet="" />
              </div>
              <div className="getInvolved-text">
                <h4>{headingData[id].title}</h4>
                <h6>{headingData[id].bodyText}</h6>
                <a href="/about/get-involved">
                  <button>Find out more</button>
                </a>
              </div>
            </>
          );
        })}
      </Col>
    </Row>
  );
};

export default GetInvolved;
