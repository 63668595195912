import React, { useState, useEffect } from "react";
import {
  Nav,
  Navbar,
  Container,
  NavDropdown,
  Offcanvas,
} from "react-bootstrap";
import logo from "../images/eus-logo1.webp";
import {
  FaTwitterSquare,
  FaInstagramSquare,
  FaLinkedin,
  FaFlickr,
} from "react-icons/fa";

export const NavigationBar = () => {
  let listener = null;
  const [scrollState, setScrollState] = useState("maroon");
  const [backgroundColor, setBackgroundColor] = useState("");

  const [show, setShow] = useState(false);
  const [showSquads, setShowSquads] = useState(false);

  const currentPath = window.location.pathname;
  const pathContainsString = currentPath.includes('admin');

  const showDropdown = (e) => {
    setShow(!show);
  };
  const hideDropdown = (e) => {
    setShow(false);
  };
  const showDropdownSquads = (e) => {
    setShowSquads(!showSquads);
  };
  const hideDropdownSquads = (e) => {
    setShowSquads(false);
  };

  const { innerWidth: width } = window;

  useEffect(() => {
    listener = document.addEventListener("scroll", (e) => {
      var scrolled = document.scrollingElement.scrollTop;
      if (width > 600) {
        if (scrolled >= 550) {
          if (backgroundColor !== "maroon") {
            setScrollState("white");
            setBackgroundColor("maroon");
          }
        } else {
          if (backgroundColor !== "") {
            setBackgroundColor("");
            setScrollState("maroon");
          }
        }
      }
      if (width < 800) {
        if (scrolled >= 150) {
          if (backgroundColor !== "maroon") {
            setScrollState("white");
            setBackgroundColor("maroon");
          }
        } else {
          if (backgroundColor !== "") {
            setBackgroundColor("");
            setScrollState("maroon");
          }
        }
      }
    });
  }, [scrollState, backgroundColor, listener]);
  return (
    <>
    { !pathContainsString ?
    <Navbar bg="blue" expand="lg" fixed="top" className={backgroundColor}>
      {/* <Container fluid> */}
      <Container>
        <Navbar.Brand href="/" className="d-block d-md-none">
          <img src={logo} alt="brand logo of the organisation" height="100" />
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="me-auto">
            <Nav.Link style={{ color: scrollState }} href="/">
              Home
            </Nav.Link>
            <NavDropdown
              title="EUS"
              id="basic-nav-dropdown"
              show={show}
              onMouseEnter={showDropdown}
              onMouseLeave={hideDropdown}
            >
              <NavDropdown.Item href="/about/about-us">
                About Us
              </NavDropdown.Item>
              <NavDropdown.Item href="/about/staff-and-coaching">
                Staffing and Coaching
              </NavDropdown.Item>
              {/* <NavDropdown.Item href="/about/student-testimonials">
                Student Testimonials
              </NavDropdown.Item> */}
              <NavDropdown.Item href="/about/partners">
                Partners
              </NavDropdown.Item>
              <NavDropdown.Item href="/about/patrons">Patrons</NavDropdown.Item>
              <NavDropdown.Item href="/about/get-involved">
                Get Involved
              </NavDropdown.Item>
              {/* <NavDropdown.Item href="/vacancies">Vacancies</NavDropdown.Item> */}
            </NavDropdown>

            <Nav.Link style={{ color: scrollState }} href="/news">
              News
            </Nav.Link>

            <NavDropdown
              title="Squads"
              id="basic-nav-dropdown"
              show={showSquads}
              onMouseEnter={showDropdownSquads}
              onMouseLeave={hideDropdownSquads}
            >
              <NavDropdown.Item href="/Squads">Squads</NavDropdown.Item>
              <NavDropdown.Item href="/squads/-N_mPOXiLLBZWIQrUmDW">
                Basketball - Men's
              </NavDropdown.Item>
              <NavDropdown.Item href="/squads/-N_mOLdgWzyWzlBUrRE5">
                Basketball - Women's
              </NavDropdown.Item>
              <NavDropdown.Item href="/squads/-MzB-XEVBJx76Yiw7uI_">
                Football - Men's
              </NavDropdown.Item>
              <NavDropdown.Item href="/squads/-MzB-nGJZ4yMF72WiPmO">
                Football - Women's
              </NavDropdown.Item>
              <NavDropdown.Item href="/squads/-MzB0miNNpLZw0nDcIDt">
                Hockey - Men's
              </NavDropdown.Item>
              <NavDropdown.Item href="/squads/-MzB0rfxg-kfawDxgK2z">
                Hockey - Women's
              </NavDropdown.Item>
              <NavDropdown.Item href="/squads/-MzB12UMB2OvYF1eueG5">
                Netball
              </NavDropdown.Item>
              <NavDropdown.Item href="/squads/-MzB-ucF_2hFgjtzfM6K">
                Rugby - Men's
              </NavDropdown.Item>
              <NavDropdown.Item href="/squads/-MzB000-lsXsQwxv8WLT">
                Rugby - Women's
              </NavDropdown.Item>
            </NavDropdown>
            <Nav.Link style={{ color: scrollState }} href="/shop">
              Shop
            </Nav.Link>
          </Nav>
          <Navbar.Brand href="/" className="d-none d-md-block nav-img">
            <img
              src={logo}
              alt="brand logo of the organisation"
              height="85px"
            />
          </Navbar.Brand>
          <Nav className="ml-auto d-none d-md-block">
            <a
              href="https://twitter.com/EngUniSport"
              target="_blank"
              style={{ marginRight: "10px" }}
            >
              <FaTwitterSquare size="2.5rem" color={scrollState} />
            </a>
            <a
              href="https://www.instagram.com/engunisport/"
              style={{ marginRight: "10px" }}
              target="_blank"
            >
              <FaInstagramSquare size="2.5rem" color={scrollState} />
            </a>
            <a
              href="https://www.linkedin.com/company/english-universities-sport/"
              style={{ marginRight: "10px" }}
              target="_blank"
            >
              <FaLinkedin size="2.5rem" color={scrollState} />
            </a>
            <a
              href="https://www.flickr.com/photos/149821371@N02/albums?"
              style={{ marginRight: "10px" }}
              target="_blank"
            >
              <FaFlickr size="2.5rem" color={scrollState} />
            </a>
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar> : null}
    </>
  );
};
