import React, { useState, useEffect } from "react";
import { SectionTitle } from "../shared/SectionTitle";
import netball from "../images/netball.webp";
import { ParallaxSection } from "../shared/ParallaxSection";
import fireDb from "../firebase";

import { Row, Col, Container, Card } from "react-bootstrap";
import { DownIcon } from "../shared/DownIcon";

export const Squads = () => {
  var [eventObjects, setEventObjects] = useState({});
  var [squadsParalax, setSquadsParalax] = useState({});
  var [headingData, setHeadingData] = useState({});

  useEffect(() => {
    fireDb
      .database()
      .ref()
      .child("HeroHeader")
      .orderByChild("key")
      .equalTo("Squads")
      .on("value", (snapshot) => {
        if (snapshot.val() != null)
          setHeadingData({
            ...snapshot.val(),
          });
      });
  }, []);

  useEffect(() => {
    fireDb
      .database()
      .ref()
      .child("SquadsParalax")
      .on("value", (snapshot) => {
        if (snapshot.val() != null)
          setSquadsParalax({
            ...snapshot.val(),
          });
      });
  }, []);

  useEffect(() => {
    fireDb
      .database()
      .ref()
      .child("Squads")
      .on("value", (snapshot) => {
        if (snapshot.val() != null)
          setEventObjects({
            ...snapshot.val(),
          });
      });
  }, []);
  return (
    <div className="squads">
      {Object.keys(headingData).map((id) => {
        return (
          <>
            <div className="squads-hero-image-layer">
              <img
                src={headingData[id].imageSource}
                alt="lions den"
                className="squads-hero-image"
              ></img>
            </div>
            <div>
              <div className="squads-hero-box d-none d-md-block">
                <h1>{headingData[id].title}</h1>
                <h4>{headingData[id].bodyText}</h4>
              </div>
            </div>
          </>
        );
      })}
      {Object.keys(squadsParalax).map((id) => {
        return (
          <Row className="squads-info-section">
            <Col xs={12} lg={5} className="squads-text">
              <SectionTitle title={squadsParalax[id].heading} />
              <div className="squads-body-text">
                <p>{squadsParalax[id].BodyText}</p>
              </div>
            </Col>
            <Col xs={12} lg={7}>
              <ParallaxSection
                image={squadsParalax[id].imageSrc}
                height={800}
              />
            </Col>
          </Row>
        );
      })}

      <Container>
        <Row>
          <Col>
            <SectionTitle title="Squad List's" />
            <br /> <br />
          </Col>
        </Row>

        <DownIcon />
        <Row className="squad-list-section">
          {Object.keys(eventObjects).map((id) => {
            return (
              <Col xs={9} lg={4} xl={3} key={id}>
                <Card>
                  <Card.Img
                    variant="top"
                    src={eventObjects[id].imageSrc}
                    // style={{ height: "200px", width: "250px" }}
                    className="card-img-mob"
                  />
                  <Card.Body>
                    <a href={"/squads/" + id}>
                      <Card.Title>{eventObjects[id].titleOfSport}</Card.Title>
                    </a>
                  </Card.Body>
                </Card>
              </Col>
            );
          })}
        </Row>
      </Container>
    </div>
  );
};
