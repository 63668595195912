import React, { useEffect, useState } from "react";
import HeroBannerHeader from "../shared/HeroBannerHeader";
import { Row, Col, Container } from "react-bootstrap";
import { PartnersSection } from "./partners/PartnersSection";
import { PatronSection } from "./partners/PatronSection";
import fireDb from "../firebase";

const Patrons = () => {
  var [headingData, setHeadingData] = useState({});

  useEffect(() => {
    fireDb
      .database()
      .ref()
      .child("HeroHeader")
      .orderByChild("key")
      .equalTo("Patrons")
      .on("value", (snapshot) => {
        if (snapshot.val() != null)
          setHeadingData({
            ...snapshot.val(),
          });
      });
  }, []);
  return (
    <>
      {Object.keys(headingData).map((id) => {
        return (
          <HeroBannerHeader
            title={headingData[id].title}
            image={headingData[id].imageSource}
          />
        );
      })}
      <Container>
      <Row className="partners-patrons-contact-container">
        <Col xs={1} md={3}></Col>
        <Col xs={10} md={6} className="partners-patrons-contact">
          <h4>Looking to become a Patrons?</h4>
          <span>
            We run as a charity and as such, we would greatly appreicate all
            donations. By becoming a Patron, you join an extraordinary group of
            donors who share our passion for supporting sporting excellence.
          </span>
          <a href="mailto:info@englishuniversitiessport.onmicrosoft.com">
            <button>Contact Us Today</button>
          </a>
        </Col>
        <Col xs={3}></Col>
      </Row>
      </Container>
      <PatronSection />
    </>
  );
};

export default Patrons;
