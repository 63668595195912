import React, { useState, useEffect } from "react";
import { BiRightArrowCircle } from "react-icons/bi";
import { Row, Col } from "react-bootstrap";
import BucsImage from "../../images/buc.webp";
import fireDb from "../../firebase";

const Bucs = () => {
  var [eventObjects, setEventObjects] = useState({});
  useEffect(() => {
    fireDb
      .database()
      .ref()
      .child("Bucs")
      .on("value", (snapshot) => {
        if (snapshot.val() != null)
          setEventObjects({
            ...snapshot.val(),
          });
      });
  }, []);
  return (
    <Row
      className="bucs-section"
      style={{ marginTop: "20px", marginRight: "0px", marginLeft: "0px" }}
    >
      {Object.keys(eventObjects).map((id) => {
        return (
          <>
            <Col xs={0} lg={1}></Col>
            <Col xs={12} md={5} lg={3} className="bucs-section-img">
              <img src={eventObjects[id].imgSource} alt="" srcSet="" />
            </Col>
            <Col xs={0}  md={1} lg={1}></Col>

            <Col xs={12} md={6} lg={5} className="bucs-section-text">
              <span>{eventObjects[id].bodyText}</span>
              <div className="carousel-btn">
                <a href={eventObjects[id].linkToWebsite} target="_blank">
                  <span>Learn More</span>
                </a>
                <div className="button-icon">
                  <BiRightArrowCircle color="maroon" size="large" />
                </div>
              </div>
            </Col>
          </>
        );
      })}
    </Row>
  );
};

export default Bucs;
