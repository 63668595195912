import React, { useState, useEffect } from "react";
import { Col, Container, Row } from "react-bootstrap";
import fireDb from "../firebase.js";
import HeroBannerHeader from "../shared/HeroBannerHeader.jsx";

export const SquadsItem = ({ item }) => {
  var [eventObjects, setEventObjects] = useState({});

  useEffect(() => {
    fireDb
      .database()
      .ref()
      .child("Squads")
      .child(item)
      .on("value", (snapshot) => {
        if (snapshot.val() != null)
          setEventObjects({
            ...snapshot.val(),
          });
      });
  }, [item]);
  var listOfSquads = eventObjects?.squadList?.split(", ");
  return (
    <div className="squad-item-page">
      <HeroBannerHeader
        title={eventObjects.titleOfSport}
        image={eventObjects.imageSrc}
      />
      <Row className="squad-item-text-box">
      <div className="mob-banner-title"><h1>{eventObjects.titleOfSport}</h1></div>
        <Col md={6}>
          <p className="squad-item-text-box-main-text">
            {eventObjects.bodyText}
          </p>
        </Col>
        <Col md={6}>
          <div className="sq-list-members">
            <h4>Squad Members</h4>
            <ul>
              {listOfSquads?.map((d) => (
                <li key={d.id}>{d}</li>
              ))}
            </ul>
          </div>
        </Col>
      </Row>
    </div>
  );
};
