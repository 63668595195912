import React, { useState } from "react";
import { Col, Modal, Button, Card } from "react-bootstrap";

export const IndividualCompetitions = ({
  title,
  modalBodyText,
  modalBodyText2,
  modalBodyText3,
  colNum,
  image,
  cardBodyText,
}) => {
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  return (
    <>
      <Col md={colNum * 1.5} lg={colNum}>
        <Card
          style={{
            width: "min-content",
            height: "auto",
            margin: "auto",
            marginTop: "20px",
          }}
        >
          <Card.Img
            variant="top"
            src={image}
            style={{
              height: "200px",
              width: "300px",
              margin: "auto",
              borderBottom: "1px solid lightGrey",
            }}
          />
          <Card.Body>
            <Card.Title style={{ color: "maroon", fontWeight: "700" }}>
              {title}
            </Card.Title>
            <Card.Text className="card-text-0">{cardBodyText}</Card.Text>
            <Button variant="primary" onClick={handleShow}>
              Learn More
            </Button>
          </Card.Body>
        </Card>
      </Col>

      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>{title}</Modal.Title>
        </Modal.Header>
        <Modal.Body style={{ textAlign: "left" }}>
          {modalBodyText}
          {modalBodyText2 && modalBodyText2}
          {modalBodyText3 && modalBodyText3}
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};
