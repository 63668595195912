import React, { useState, useEffect } from 'react';
import './styling/MobileNavBar.css';
import logo from "../images/eus-logo1.webp";

const MobileNavBar = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [scrollPassedThreshold, setScrollPassedThreshold] = useState(false);
  const [isSquadOpen, setIsSquadOpen] = useState(false);
  const [isEUSOpen, setIsEUSOpen] = useState(false);

  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };

  const toggleSquadDropdown = () => {
    setIsSquadOpen(!isSquadOpen);
    setIsEUSOpen(false);
  };

  const toggleEUSDropdown = () => {
    setIsEUSOpen(!isEUSOpen);
    setIsSquadOpen(false);
  };

  useEffect(() => {
    const handleScroll = () => {
      const scrolled = window.scrollY;
      const threshold = 0;
      setScrollPassedThreshold(scrolled > threshold);
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return (
    <div className={`mobile-navbar ${isOpen ? 'open' : 'closed'} ${scrollPassedThreshold ? 'scrolled' : ''}`}>
      <a href="/"> <img src={logo} alt="Logo" className="logo" /></a>
      <div className={`hamburger ${scrollPassedThreshold ? 'scrolled' : ''}`} onClick={toggleMenu}>
        &#9776;
      </div>
      {isOpen && (
        <div className="overlay">
          <div className="close-btn" onClick={toggleMenu}>
            &times;
          </div>
          <div className="overlay-content">
            <a href="/">Home</a>
            <div className="dropdown">
              <a href="#" style={{color: "maroon"}} onClick={toggleEUSDropdown}>EUS</a>
              {isEUSOpen && (
                <div className="dropdown-content">
                  <a href="/about/about-us">About Us</a>
                  <a href="/about/staff-and-coaching">Staff</a>
                  <a href="/about/partners">Partners</a>
                  <a href="/about/patrons">Patrons</a>
                  <a href="/about/get-involved">Get Involved</a>

                </div>
              )}
            </div>
            <a href="/news">News</a>
            <div className="dropdown">
              <a href="#" style={{color: "maroon"}} onClick={toggleSquadDropdown}>Squads</a>
              {isSquadOpen && (
                <div className="dropdown-content dropdown-content-high">
                    <a href="/Squads">Squads</a>
              <a href="/squads/-N_mPOXiLLBZWIQrUmDW">
                Basketball(M)
              </a>
              <a href="/squads/-N_mOLdgWzyWzlBUrRE5">
                Basketball(W)
              </a>
              <a href="/squads/-MzB-XEVBJx76Yiw7uI_">
                Football(M)
              </a>
              <a href="/squads/-MzB-nGJZ4yMF72WiPmO">
                Football(W)
              </a>
              <a href="/squads/-MzB0miNNpLZw0nDcIDt">
                Hockey(M)
              </a>
              <a href="/squads/-MzB0rfxg-kfawDxgK2z">
                Hockey(W)
              </a>
              <a href="/squads/-MzB12UMB2OvYF1eueG5">
                Netball
              </a>
              <a href="/squads/-MzB-ucF_2hFgjtzfM6K">
                Rugby(M)
              </a>
              <a href="/squads/-MzB000-lsXsQwxv8WLT">
                Rugby(W)
              </a>
                </div>
              )}
            </div>
            <a href="/shop">Shop</a>
          </div>
        </div>
      )}
    </div>
  );
};

export default MobileNavBar;


