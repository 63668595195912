import React, { useState, useEffect } from "react";

export const GetInvolvedAdminForm = (props) => {
  const initialFieldValues = {
    buttonTitle: "",
    emailButtonLink: "",
    toggle: "",
  };

  var [values, setValues] = useState(initialFieldValues);

  useEffect(() => {
    if (props.currentId === "")
      setValues({
        ...initialFieldValues,
      });
    else
      setValues({
        ...props.eventObjects[props.currentId],
      });
  }, [props.currentId, props.eventObjects]);

  const handleInputChange = (e) => {
    var { name, value } = e.target;
    setValues({
      ...values,
      [name]: value,
    });
  };

  const handleFormSubmit = (e) => {
    e.preventDefault();
    props.addOrEdit(values);
  };

  return (
    <>
      <form autoComplete="off" onSubmit={handleFormSubmit}>
        <input
          type="text"
          placeholder="Button Label"
          name="buttonTitle"
          value={values.buttonTitle}
          onChange={handleInputChange}
        />
        <textarea
          type="text"
          placeholder="Email Address Link"
          name="emailButtonLink"
          value={values.emailButtonLink}
          onChange={handleInputChange}
        />
        <textarea
          type="text"
          placeholder="toggle key"
          name="toggle"
          value={values.toggle}
          onChange={handleInputChange}
        />
        <input
          type="submit"
          value={props.currentId === "" ? "Save" : "Update"}
        />
      </form>
    </>
  );
};

export default GetInvolvedAdminForm;
