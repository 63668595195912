import React from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import { Squads } from "./Squads";
import { SquadsItem } from "./SquadsItem";

const SquadsContainer = (props) => {
  return (
    <Router>
      <Switch>
        <Route
          exact
          path="/squads/:id"
          render={(props) => {
            const id = props.match.params.id;
            return <SquadsItem item={id} />;
          }}
        />
        <Route
          render={(props) => {
            return <Squads />;
          }}
        />
      </Switch>
    </Router>
  );
};

export default SquadsContainer;
