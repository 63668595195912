import React, { useState, useEffect } from "react";
import { AiOutlineSync } from "react-icons/ai";
import fireDb from "../firebase";

export const UploadFileForm = (props) => {
  const [fileUrl, setFileUrl] = useState("");

  const initialFieldValues = {
    nameOfDocument: "",
    file: "",
  };

  var [values, setValues] = useState(initialFieldValues);

  useEffect(() => {
    if (props.currentId === "")
      setValues({
        ...initialFieldValues,
      });
    else
      setValues({
        ...props.eventObjects[props.currentId],
      });
  }, [props.currentId, props.eventObjects]);

  const handleInputChange = (e) => {
    var { name, value } = e.target;
    setValues({
      ...values,
      [name]: value,
    });
  };

  const handleFormSubmit = async (e) => {
    e.preventDefault();
    values.file = fileUrl;
    props.addOrEdit(values);
  };

  const onFileChange = async (e) => {
    const file = e.target.files[0];
    const storageRef = fireDb.storage().ref();
    const fileRef = storageRef.child(file.name);
    // fileRef.put(file).then(() => {
    //   console.log("updated file", file.name);
    // });
    await fileRef.put(file);
    console.log(await fileRef.getDownloadURL());
    setFileUrl(await fileRef.getDownloadURL());
  };
  return (
    <>
      <form autoComplete="off" onSubmit={handleFormSubmit}>
        <input
          type="file"
          placeholder="File"
          name="file"
          // value={values.file}
          onChange={onFileChange}
        />
        <input
          type="text"
          placeholder="Document Name"
          name="nameOfDocument"
          value={values.nameOfDocument}
          onChange={handleInputChange}
        />

        <input
          type="submit"
          value={props.currentId === "" ? "Save" : "Update"}
        />
      </form>
    </>
  );
};

export default UploadFileForm;
