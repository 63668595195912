import React from "react";
import { Parallax } from "react-parallax";

export const ParallaxSection = ({ image, height }) => {
  return (
    <Parallax bgImage={image} strength={500}>
      <div style={{ height: height }}></div>
    </Parallax>
  );
};
