import React, { useState, useEffect } from "react";
import { Col, Container, Row } from "react-bootstrap";
import fireDb from "../firebase.js";
import { SectionTitle } from "../shared/SectionTitle.jsx";
import photo from "../images/rugby.webp";
import { ParallaxSection } from "../shared/ParallaxSection.jsx";
import { AiOutlineDownload } from "react-icons/ai";
import HeroBannerHeader from "../shared/HeroBannerHeader.jsx";

export const NewsListItem = ({ item }) => {
  var [eventObjects, setEventObjects] = useState({});
  var [paralaxImage, setParalaxImage] = useState({});

  useEffect(() => {
    fireDb
      .database()
      .ref()
      .child("News")
      .child(item)
      .on("value", (snapshot) => {
        if (snapshot.val() != null)
          setEventObjects({
            ...snapshot.val(),
          });
      });
  }, [item]);

  useEffect(() => {
    fireDb
      .database()
      .ref()
      .child("NewsItemImage")
      .on("value", (snapshot) => {
        if (snapshot.val() != null)
          setParalaxImage({
            ...snapshot.val(),
          });
      });
  }, []);

  return (
    <div className="news-item-page">
      {Object.keys(paralaxImage).map((id) => {
        return (
          <HeroBannerHeader
            image={paralaxImage[id].imageSrc}
            title="News and Updates"
          />
        );
      })}
      <Container fluid>
        <Row style={{ marginBottom: "50px", marginTop: "30px" }}>
          <Col xs={1} md={1} lg={2}></Col>
          <Col xs={12} md={10} lg={7}>
            <div className="news-item-page-text-box">
              {eventObjects.title && <h2>{eventObjects?.title}</h2>}

              <img
                className="news-item-image"
                src={eventObjects.imageSrc}
                // width="100%"
                // height="350px"
                alt="News article"
              />
              {eventObjects.title && <h4>{eventObjects?.subHeading}</h4>}

              {eventObjects.title && <p>{eventObjects?.bodyText}</p>}

              {eventObjects?.para2 && <p>{eventObjects?.para2}</p>}
              {eventObjects?.para3 && <p>{eventObjects?.para3}</p>}
              {eventObjects?.para4 && <p>{eventObjects?.para4}</p>}
              {eventObjects?.para5 && <p>{eventObjects?.para5}</p>}
              {eventObjects?.para6 && <p>{eventObjects?.para6}</p>}
              {eventObjects?.para7 && <p>{eventObjects?.para7}</p>}
              {eventObjects?.para8 && <p>{eventObjects?.para8}</p>}
              {eventObjects?.para9 && <p>{eventObjects?.para9}</p>}
              {eventObjects?.para10 && <p>{eventObjects?.para10}</p>}
              {eventObjects.downloadLink && eventObjects.downloadButtonText && (
                <a href={eventObjects?.downloadLink}>
                  <button
                    style={{
                      backgroundColor: "maroon",
                      color: "white",
                      fontWeight: "bold",
                    }}
                  >
                    {eventObjects?.downloadButtonText}{" "}
                  </button>
                </a>
              )}
              {eventObjects.downloadLink2 && eventObjects.downloadButtonText2 && (
                <a href={eventObjects?.downloadLink2}>
                  <button
                    style={{
                      backgroundColor: "maroon",
                      color: "white",
                      fontWeight: "bold",
                    }}
                  >
                    {eventObjects?.downloadButtonText2}{" "}
                  </button>
                </a>
              )}
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  );
};
