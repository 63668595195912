import React, { useState, useEffect } from "react";

export const HPCaroAdminForm = (props) => {
  const initialFieldValues = {
    imageSrc: "",
    mobimageSrc: "",
    Heading: "",
    ButtonText: "",
    ButtonLink: "",
  };

  var [values, setValues] = useState(initialFieldValues);

  useEffect(() => {
    if (props.currentId === "")
      setValues({
        ...initialFieldValues,
      });
    else
      setValues({
        ...props.eventObjects[props.currentId],
      });
  }, [props.currentId, props.eventObjects]);

  const handleInputChange = (e) => {
    var { name, value } = e.target;
    setValues({
      ...values,
      [name]: value,
    });
  };

  const handleFormSubmit = (e) => {
    e.preventDefault();
    props.addOrEdit(values);
  };

  return (
    <>
      <form autoComplete="off" onSubmit={handleFormSubmit}>
        <input
          type="text"
          placeholder="Heading"
          name="Heading"
          value={values.Heading}
          onChange={handleInputChange}
        />
        <textarea
          type="text"
          placeholder="Button Text"
          name="ButtonText"
          value={values.ButtonText}
          onChange={handleInputChange}
        />
        <textarea
          type="text"
          placeholder="Button Link"
          name="ButtonLink"
          value={values.ButtonLink}
          onChange={handleInputChange}
        />
        <textarea
          type="text"
          placeholder="image Source"
          name="imageSrc"
          value={values.imageSrc}
          onChange={handleInputChange}
        />
        <textarea
          type="text"
          placeholder="image Source"
          name="imageSrc"
          value={values.mobimageSrc}
          onChange={handleInputChange}
        />
        <input
          type="submit"
          value={props.currentId === "" ? "Save" : "Update"}
        />
      </form>
    </>
  );
};

export default HPCaroAdminForm;
