import React, { useState, useEffect } from "react";
import fireDb from "../../firebase";
import { Row, Col } from "react-bootstrap";
import { ParallaxSection } from "../../shared/ParallaxSection";

export const AboutUsSection = () => {
  var [eventObjects, setEventObjects] = useState({});

  useEffect(() => {
    fireDb
      .database()
      .ref()
      .child("AboutUs")
      .on("value", (snapshot) => {
        if (snapshot.val() != null)
          setEventObjects({
            ...snapshot.val(),
          });
      });
  }, []);
  return (
    <>
      {Object.keys(eventObjects).map((id) => {
        return (
          <Row className="row1" key={id} style={{ marginRight: "0px" }}>
            <Col xs={0} sm={0} md={1}></Col>
            <Col
              xs={12}
              sm={12}
              md={0}
              className="col1-sm"
              style={{ paddingRight: "0px" }}
            >
              <ParallaxSection
                image={eventObjects[id]?.imageSrc}
                height={400}
              />
            </Col>
            <Col xs={12} md={5} className="col2">
              <h1>{eventObjects[id]?.title}</h1>
              <p>{eventObjects[id]?.bodyText}</p>
            </Col>
            <Col
              xs={0}
              sm={0}
              md={6}
              className="col1"
              style={{ paddingRight: "0px" }}
            >
              <ParallaxSection
              image={eventObjects[id]?.imageSrc}
              height={800}
              />
            </Col>
          </Row>
        );
      })}
    </>
  );
};
