import React from 'react';
import { Row, Col } from 'react-bootstrap'; // Assuming you are using react-bootstrap

const PageContent = ({ selectedPage, selectedSubPage }) => {
  const renderPageContent = () => {
    if (selectedPage === 'eus' && selectedSubPage) {
      switch(selectedSubPage) {
        case 'aboutus':
          return (
          <Row className="admin-home-nav">
            <Col xs={6} md={4}>
              <a href="/aboutus-admin">
                <button>About Us</button>
              </a>
            </Col>
          </Row> 
          );
        case 'staff':
          return (
            <Row className="admin-home-nav">
              <Col xs={6} md={4}>
                <a href="/coaching-admin">
                  <button>Coaches</button>
                </a>
              </Col>
              <Col xs={6} md={4}>
                <a href="/committee-admin">
                  <button>Committee</button>
                </a>
              </Col>
            </Row> 
            );
        case 'partners':
          return (
            <Row className="admin-home-nav">
              <Col xs={6} md={4}>
                <button>
                  <a href="/members-admin">Partners</a>
                </button>
              </Col>
            </Row> 
            );
        case 'patrons':
          return (
            <Row className="admin-home-nav">
               <Col xs={6} md={4}>
                <a href="/patron-admin">
                  <button>Patrons</button>
                </a>
              </Col>
            </Row> 
            );
        case 'getinvolved':
          return (
            <Row className="admin-home-nav">
              <Col xs={6} md={4}>
                <a href="/getInvolved-admin">
                  <button>Get Involved</button>
                </a>
              </Col>
            </Row> 
            );
        default:
          return null;
        
      }
    } else {
      switch(selectedPage) {
        case 'homepage':
          return (
            <Row className="admin-home-nav">
              <Col xs={6} md={4}>
                <a href="/hp-caro-admin">
                  <button>Hero Carousel Image</button>
                </a>
              </Col>

            <Col xs={6} md={4}>
              <a href="/aboutus-admin">
                <button>About Us</button>
              </a>
            </Col>
            <Col xs={6} md={4}>
              <a href="/comp-admin">
                <button>Competitions</button>
              </a>
            </Col>

            <Col xs={6} md={4}>
              <a href="/home-get-involved-box">
                <button>Homepage Get Inv box</button>
              </a>
            </Col>

            <Col xs={6} md={4}>
              <a href="/news-admin">
                <button>News</button>
              </a>
            </Col>

            <Col xs={6} md={4}>
              <a href="/bucs-admin">
                <button>Bucs</button>
              </a>
            </Col>



            </Row>
          );
        case 'news':
          return (
            <Row className="admin-home-nav">
              <Col xs={6} md={4}>
                <a href="/news-admin">
                  <button>News</button>
                </a>
              </Col>
              <Col xs={6} md={4}>
                <a href="/News-item-image-admin">
                  <button>News Item Image Header</button>
                </a>
              </Col>
            </Row> 
          );
        case 'squads':
          return (
            <Row className="admin-home-nav">
              <Col xs={6} md={4}>
                <a href="/squad-admin">
                  <button>Squads</button>
                </a>
              </Col>
              <Col xs={6} md={4}>
                <a href="/squads-paralax-admin">
                  <button>Squads Paralax Section</button>
                </a>
              </Col>
            </Row> 
          );
        case 'shop':
          return (
            <Row className="admin-home-nav">
              <Col xs={6} md={4}>
                <a href="/shop-admin">
                  <button>Shop</button>
                </a>
              </Col>
            </Row> 
          );
        default:
          return null;
      }
    }
  };

  return <>{renderPageContent()}</>;
};

export default PageContent;
