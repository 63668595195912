import React, { useState, useEffect } from "react";
import { Row, Col, Container, Card } from "react-bootstrap";
import fireDb from "../firebase";
import HeroBannerHeader from "../shared/HeroBannerHeader";

export const News = () => {
  var [eventObjects, setEventObjects] = useState({});
  var [headingData, setHeadingData] = useState({});

  useEffect(() => {
    fireDb
      .database()
      .ref()
      .child("HeroHeader")
      .orderByChild("key")
      .equalTo("News")
      .on("value", (snapshot) => {
        if (snapshot.val() != null)
          setHeadingData({
            ...snapshot.val(),
          });
      });
  }, []);

  useEffect(() => {
    fireDb
      .database()
      .ref()
      .child("News")
      .on("value", (snapshot) => {
        if (snapshot.val() != null)
          setEventObjects({
            ...snapshot.val(),
          });
      });
  }, []);

  const reversedKeys = Object.keys(eventObjects).reverse();
  const mostRecent = reversedKeys.slice(0,4);
  return (
    <div className="news-page-container">
      {Object.keys(headingData).map((id) => {
        return (
          <HeroBannerHeader
            image={headingData[id].imageSource}
            title={headingData[id].title}
          />
        );
      })}
      {/* <Row className="news-subheading news-most-rec" style={{ marginRight: "0px" }}>
        <Col xs={1} md={0} ></Col>
        <Col xs={8} md={0}>
          <h1>Most Recent</h1>
        </Col>
      </Row>
      <Container>
        <Row className="news-item-row news-most-rec1">
          {Object.keys(mostRecent).map((id) => {
            return (
              <Col xs={9} md={4} lg={4} xl={3} key={reversedKeys[id]}>
                <Card style={{ width: "240px", height: "auto" }}>
                  <Card.Img
                    variant="top"
                    src={eventObjects[reversedKeys[id]]?.imageSrc}
                    // style={{ width: "240px", height: "160px" }}
                    className="card-img-mob"
                  />
                  <Card.Body>
                    <a href={"/news/" + [reversedKeys[id]]}>
                      <Card.Title>
                        {eventObjects[reversedKeys[id]]?.title}
                      </Card.Title>
                    </a>
                  </Card.Body>
                </Card>
              </Col>
            );
          })}
        </Row>
      </Container> */}

      <Row className="news-subheading" style={{ marginRight: "0px" }}>
        <Col xs={1}></Col>
        <Col xs={8}>
          <h1>News</h1>
        </Col>
      </Row>
      <Container>
        
        <Row className="news-item-row news-most-rec1">
          {Object.keys(reversedKeys).map((id) => {
            return (
              <Col xs={6} md={4} lg={4} xl={3} key={reversedKeys[id]}>
                <div style={{ width: "240px", height: "auto"}} className="news-card d-none d-sm-block d-lg-block">
                <a href={"/news/" + [reversedKeys[id]]}>
                  <Card.Img
                    variant="top"
                    src={eventObjects[reversedKeys[id]]?.imageSrc}
                    // style={{ height: "160px", width: "240px" }}
                    className="card-img-mob"
                  />
                  <div className="news-card-body">
                    <h6>News</h6>
                      <span>
                        {eventObjects[reversedKeys[id]]?.title}
                      </span>
                  </div>
                  </a>
                </div>

                <div style={{ width: "150px", height: "auto"}} className="news-card d-block d-sm-none d-lg-none">
                <a href={"/news/" + [reversedKeys[id]]}>
                  <Card.Img
                    variant="top"
                    src={eventObjects[reversedKeys[id]]?.imageSrc}
                    className="card-img-mob"
                  />
                  <div className="news-card-body">
                    <h6>News</h6>
                      <span>
                        {eventObjects[reversedKeys[id]]?.title}
                      </span>
                  </div>
                  </a>
                </div>
              </Col>
            );
          })}
        </Row>
      </Container>

    </div>
  );
};
