import "./App.css";
import { Home } from "./home/Home.jsx";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import { News } from "./news/News";
import { StudentTestimonials } from "./about/StudentTestimonials";
import { StaffAndCoaching } from "./about/StaffAndCoaching";
import { NavigationBar } from "./shared/NavigationBar";
import { Squads } from "./Squads/Squads";
import { Footer } from "./shared/Footer";
import GetInvolved from "./about/GetInvolved";
import PartnersAndPatrons from "./about/PartnersAndPatrons.jsx";
import Vacancies from "./Vacancies/Vacancies.jsx";
import Shop from "./shop/Shop";
import CompetitionAdminPage from "./Admin/CompetitionAdminPage";
import NewsAdminPage from "./Admin/NewsAdminPage";
import VacancyAdminPage from "./Admin/VacancyAdminPage";
import GetInvolvedAdminPage from "./Admin/GetInvolvedAdminPage";
import ShopAdminPage from "./Admin/ShopAdminPage";
import SquadAdminPage from "./Admin/SquadAdminPage";
import StudentTestAdminPage from "./Admin/StudentTestAdminPage";
import MembersAdminPage from "./Admin/MembersAdminPage";
import { AuthProvider } from "./Auth";
import Login from "./Login";
import SignUp from "./SignUp";
import PrivateRoute from "./PrivateRoute";
import NewsContainer from "./news/NewsContainer";
import Patrons from "./about/Patrons";
import AboutUs from "./about/AboutUs";
import PatronAdminPage from "./Admin/PatronAdminPage";
import SquadsContainer from "./Squads/SquadsContainter";
import CommitteeAdminPage from "./Admin/committeeAdminPage";
import CoachingAdminPage from "./Admin/CoachingAdmingPage";
import AdminHome from "./Admin/AdminHome";
import HPCaroAdminPage from "./Admin/HPCaroAdminPage";
import UploadFilePage from "./Admin/UploadFilePage";
import AboutUsAdminPage from "./Admin/AboutUsSectionAdminPage";
import HeroHeaderAdminPage from "./Admin/HeroHeaderAdminPage";
import HomepageGetInvBoxAdminPage from "./Admin/HomepageGetInvBoxAdminPage";
import BucsAdminPage from "./Admin/BucsAdminPage";
import SquadsParalaxSectionsAdminPage from "./Admin/SqaudsParalaxSectionsAdminPage";
import NewsItemImageAdminPage from "./Admin/NewsItemImageAdminPage";
import MobileNavBar from "./shared/MobileNav.jsx";
import useIsMobile from './useIsMobile';

function App() {
  const isMobile = useIsMobile();

  return (
    <AuthProvider>
      <Router>
        {/* <div className="App"> */}
        {isMobile ? <MobileNavBar />:
         <NavigationBar /> }
         
        <Switch>
          <Route exact path="/login" component={Login} />
          {/* <Route exact path="/signup" component={SignUp} /> */}
          <Route exact path="/" component={Home} />
          {/* Admin Pages */}
          <PrivateRoute exact path="/admin" component={AdminHome} />
          <PrivateRoute
            exact
            path="/comp-admin"
            component={CompetitionAdminPage}
          />
          <PrivateRoute exact path="/upload-files" component={UploadFilePage} />
          <PrivateRoute
            exact
            path="/pages-heading-component"
            component={HeroHeaderAdminPage}
          />
          <PrivateRoute
            exact
            path="/home-get-involved-box"
            component={HomepageGetInvBoxAdminPage}
          />

          <PrivateRoute
            exact
            path="/patron-admin"
            component={PatronAdminPage}
          />
          <PrivateRoute
            exact
            path="/aboutus-admin"
            component={AboutUsAdminPage}
          />

          <PrivateRoute
            exact
            path="/members-admin"
            component={MembersAdminPage}
          />
          <PrivateRoute exact path="/squad-admin" component={SquadAdminPage} />
          <PrivateRoute
            exact
            path="/hp-caro-admin"
            component={HPCaroAdminPage}
          />

          <PrivateRoute
            exact
            path="/committee-admin"
            component={CommitteeAdminPage}
          />
          <PrivateRoute
            exact
            path="/coaching-admin"
            component={CoachingAdminPage}
          />
          <PrivateRoute
            exact
            path="/members-admin"
            component={MembersAdminPage}
          />
          <PrivateRoute
            exact
            path="/student-test-admin"
            component={StudentTestAdminPage}
          />
          <PrivateRoute exact path="/news-admin" component={NewsAdminPage} />
          <PrivateRoute
            exact
            path="/vacancy-admin"
            component={VacancyAdminPage}
          />
          <PrivateRoute
            exact
            path="/getInvolved-admin"
            component={GetInvolvedAdminPage}
          />
          <PrivateRoute exact path="/bucs-admin" component={BucsAdminPage} />
          <PrivateRoute exact path="/shop-admin" component={ShopAdminPage} />
          <PrivateRoute
            exact
            path="/squads-paralax-admin"
            component={SquadsParalaxSectionsAdminPage}
          />
          <PrivateRoute
            exact
            path="/News-item-image-admin"
            component={NewsItemImageAdminPage}
          />

          {/* Website Pages */}
          {/* <Route
            exact
            path="/about/student-testimonials"
            component={StudentTestimonials}
          /> */}

          <Route exact path="/about/about-us" component={AboutUs} />
          <Route exact path="/about/partners" component={PartnersAndPatrons} />
          <Route exact path="/about/patrons" component={Patrons} />
          <Route exact path="/about/get-involved" component={GetInvolved} />

          <Route
            exact
            path="/about/staff-and-coaching"
            component={StaffAndCoaching}
          />

          <Route path="/squads" component={SquadsContainer} />
          <Route path="/news" component={NewsContainer} />
          {/* <Route exact path="/vacancies" component={Vacancies} /> */}
          <Route exact path="/shop" component={Shop} />
        </Switch>
        {/* {window.location.href.includes("/about/partners") ? null : <Footer />} */}
        <Footer />
        <div
          style={{ backgroundColor: "black", color: "white", height: "100%" }}
        >
          <span>
            Created by{" "}
            <a
              style={{ color: "white" }}
              target="_blank"
              href="https://nazrul-liton.web.app"
            >
              Naz Liton @ 3Five Software Development.
            </a>
          </span>
        </div>
        {/* </div> */}
      </Router>
    </AuthProvider>
  );
}

export default App;
