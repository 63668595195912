import React, { useState, useEffect } from "react";
import fireDb from "../../firebase";
import { Col, Row, Container } from "react-bootstrap";
import { PatronSection } from "./PatronSection";

export const PartnersSection = ({ title }) => {
  var [eventObjects, setEventObjects] = useState({});

  useEffect(() => {
    fireDb
      .database()
      .ref()
      .child("Members")
      .orderByChild("filterKey")
      .equalTo("Partner")
      .on("value", (snapshot) => {
        if (snapshot.val() != null)
          setEventObjects({
            ...snapshot.val(),
          });
      });
  }, []);
  let url = window.location.pathname;

  return (
    <>
      <Container fluid>
        <Row className="partner-sections">
          {url === "/about/partners" ? (
            <>
              {/* <Col xs={1}></Col>
              <Col xs={12} md={8} className="partner-section-title">
                <h1>{title}</h1>
              </Col>
              <Col xs={0} md={3}></Col> */}
            </>
          ) : (
            <>
              <Col xs={0} md={3}></Col>
              <Col xs={12} md={9} className="partner-section-title">
                <h1>{title}</h1>
              </Col>
            </>
          )}
          {url === "/about/partners" ? (
            <>
              <PatronSection />
            </>
          ) : (
            <>
              {Object.keys(eventObjects).map((id) => {
                return (
                  <Col md={6} lg={3} key={id}>
                    <div className="partner-logo-img">
                      <a href={eventObjects[id]?.linkToMember} target="_blank">
                        <img src={eventObjects[id]?.imageSource} alt="" />
                      </a>
                    </div>
                  </Col>
                );
              })}
            </>
          )}
        </Row>
      </Container>
    </>
  );
};
