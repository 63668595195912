import React, { useEffect, useState } from "react";
import HeroBannerHeader from "../shared/HeroBannerHeader";
import { Row, Col } from "react-bootstrap";
import { PartnersSection } from "./partners/PartnersSection";
import { PatronSection } from "./partners/PatronSection";
import fireDb from "../firebase";

const PartnersAndPatrons = () => {
  var [headingData, setHeadingData] = useState({});

  useEffect(() => {
    fireDb
      .database()
      .ref()
      .child("HeroHeader")
      .orderByChild("key")
      .equalTo("Partners")
      .on("value", (snapshot) => {
        if (snapshot.val() != null)
          setHeadingData({
            ...snapshot.val(),
          });
      });
  }, []);
  return (
    <div className="partner-page-container">
      {Object.keys(headingData).map((id) => {
        return (
          <HeroBannerHeader
            title={headingData[id].title}
            image={headingData[id].imageSource}
          />
        );
      })}
      <Row
        className="partners-patrons-contact-container"
        style={{ marginRight: "0px" }}
      >
        <Col xs={1} md={3}></Col>
        <Col xs={10} md={6} className="partners-patrons-contact">
          <br />
          <h4>Looking to become a partner?</h4>
          <span>
            Our organisation supports and provides structure to various sports
            across the country, including rugby, football and netball. Our
            athletes are the future stars of tomorrow, If you would like to
            partner with us and can meet our criteria of acceptance, please
            contact us below.
          </span>
          <a href="mailto:info@englishuniversitiessport.onmicrosoft.com">
            <button>Find out more</button>
          </a>
          <br />
        </Col>
        <Col xs={1} md={3}></Col>
      </Row>
      <PartnersSection title="Partners" />
    </div>
  );
};

export default PartnersAndPatrons;
