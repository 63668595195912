import React from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import { News } from "./News";
import { NewsListItem } from "./NewsListItem";

const NewsContainer = (props) => {
  return (
    <Router>
      <Switch>
        <Route
          exact
          path="/news/:id"
          render={(props) => {
            const id = props.match.params.id;
            return <NewsListItem item={id} />;
          }}
        />
        <Route
          render={(props) => {
            return <News />;
          }}
        />
      </Switch>
    </Router>
  );
};

export default NewsContainer;
