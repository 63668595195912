import React, { useState, useEffect } from "react";
import fireDb from "../firebase";
import { FaPencilAlt, FaTrash } from "react-icons/fa";
import { Col, Row } from "react-bootstrap";
import SignOut from "../shared/SignOut";
import UploadFileForm from "./UploadFileForm";

export const UploadFilePage = () => {
  var [eventObjects, setEventObjects] = useState({});
  var [currentId, setCurrentId] = useState("");

  useEffect(() => {
    fireDb
      .database()
      .ref()
      .child("UploadedFiles")
      .on("value", (snapshot) => {
        if (snapshot.val() != null)
          setEventObjects({
            ...snapshot.val(),
          });
      });
  }, []);

  const addOrEdit = (obj) => {
    if (currentId === "")
      fireDb
        .database()
        .ref()
        .child("UploadedFiles")
        .push(obj, (err) => {
          if (err) console.log();
          else setCurrentId("");
        });
    else
      fireDb
        .database()
        .ref()
        .child(`UploadedFiles/${currentId}`)
        .set(obj, (err) => {
          if (err) console.log(err);
          else setCurrentId("");
        });
  };

  const onDelete = (id) => {
    if (window.confirm("Are you sure to delete this record")) {
      fireDb
        .database()
        .ref()
        .child(`UploadedFiles/${id}`)
        .remove((err) => {
          if (err) console.log(err);
          else setCurrentId("");
        });
      window.location.reload(false);
    }
  };

  return (
    <div style={{ height: "auto", backgroundColor: "white" }}>
      <div style={{ height: "100px" }}></div>
      <br />
      <SignOut />
      <Row>
        <Col xs={4}></Col>
        <Col xs={4}>
          <h1>Uploaded Files Section</h1>
        </Col>
      </Row>
      <br />
      <Row>
        <Col xs={2}></Col>
        <Col xs={3} className="admin-form">
          <h3>Upload New File</h3>
          <UploadFileForm {...{ addOrEdit, currentId, eventObjects }} />
        </Col>
        <Col xs={2}></Col>

        <Col
          xs={3}
          // className="admin-form"
        >
          <div
            style={{
              height: "auto",
              width: "80%",
              border: "1px solid black",
              boxShadow: "5px 10px #888888",
              marginBottom: "20px",
              padding: "10px 5px",
            }}
          >
            <br />
            <h5>Top Tips!</h5>
            <p>
              1. Once you press submit, the file is uploaded to our storage and
              database record is saved with a reference(pointer) to the file and
              name.
            </p>
            <p>
              2. You can upload files, images and videos and then use a
              reference link to show the file on our website.
            </p>
            <p>
              3. Please ensure you reduce the size of images and videos to fit
              your purpose. e.g. we dont require 4k image for a small image
              block, you can resize or change image quality.
            </p>
            <p>
              4. Once uploaded, copy the file source link and paste in a image
              source/download source field.
            </p>
            <p>
              5. Once the file is no longer required please, delete the record
              using the delete button below.
            </p>
          </div>
        </Col>
      </Row>
      <Row>
        <Col xs={12} className="admin-list">
          <h3>List of Competitions</h3>
          <table>
            <thead>
              <tr>
                <th style={{ minWidth: "120px" }}>Document Name</th>
                <th style={{ minWidth: "200px" }}>File Source</th>

                <th>Edit/Delte</th>
              </tr>
            </thead>
            <tbody>
              {Object.keys(eventObjects).map((id) => {
                return (
                  <tr key={id}>
                    <td>{eventObjects[id].nameOfDocument}</td>
                    <td>{eventObjects[id].file}</td>

                    <td>
                      <div
                        className="btn text-primary"
                        onClick={() => {
                          setCurrentId(id);
                        }}
                      >
                        <FaPencilAlt />
                      </div>
                      <div
                        className="btn text-danger"
                        onClick={() => {
                          onDelete(id);
                        }}
                      >
                        <FaTrash />
                      </div>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </Col>
      </Row>
    </div>
  );
};

export default UploadFilePage;
