import React, { useState, useEffect } from "react";

export const BucsAdminForm = (props) => {
  const initialFieldValues = {
    imgSource: "",
    bodyText: "",
    linkToWebsite: "",
  };

  var [values, setValues] = useState(initialFieldValues);

  useEffect(() => {
    if (props.currentId === "")
      setValues({
        ...initialFieldValues,
      });
    else
      setValues({
        ...props.eventObjects[props.currentId],
      });
  }, [props.currentId, props.eventObjects]);

  const handleInputChange = (e) => {
    var { name, value } = e.target;
    setValues({
      ...values,
      [name]: value,
    });
  };

  const handleFormSubmit = (e) => {
    e.preventDefault();
    props.addOrEdit(values);
  };

  return (
    <>
      <form autoComplete="off" onSubmit={handleFormSubmit}>
        <input
          type="text"
          placeholder="Image Source"
          name="imgSource"
          value={values.imgSource}
          onChange={handleInputChange}
        />
        <textarea
          type="text"
          placeholder="Body Text"
          name="bodyText"
          value={values.bodyText}
          onChange={handleInputChange}
        />
        <textarea
          type="text"
          placeholder="Link To Website"
          name="linkToWebsite"
          value={values.linkToWebsite}
          onChange={handleInputChange}
        />
        <input
          type="submit"
          value={props.currentId === "" ? "Save" : "Update"}
        />
      </form>
    </>
  );
};

export default BucsAdminForm;
