import React, { useState, useEffect } from "react";

export const ShopAdminForm = (props) => {
  const initialFieldValues = {
    nameOfProductLine: "",
    linkToProduct: "",
    imageSource: "",
  };

  var [values, setValues] = useState(initialFieldValues);

  useEffect(() => {
    if (props.currentId === "")
      setValues({
        ...initialFieldValues,
      });
    else
      setValues({
        ...props.eventObjects[props.currentId],
      });
  }, [props.currentId, props.eventObjects]);

  const handleInputChange = (e) => {
    var { name, value } = e.target;
    setValues({
      ...values,
      [name]: value,
    });
  };

  const handleFormSubmit = (e) => {
    e.preventDefault();
    props.addOrEdit(values);
  };

  return (
    <>
      <form autoComplete="off" onSubmit={handleFormSubmit}>
        <input
          type="text"
          placeholder="Name Of Line"
          name="nameOfProductLine"
          value={values.nameOfProductLine}
          onChange={handleInputChange}
        />
        <textarea
          type="text"
          placeholder="Link To Product"
          name="linkToProduct"
          value={values.linkToProduct}
          onChange={handleInputChange}
        />
        <textarea
          type="text"
          placeholder="Image Source"
          name="imageSource"
          value={values.imageSource}
          onChange={handleInputChange}
        />
        <input
          type="submit"
          value={props.currentId === "" ? "Save" : "Update"}
        />
      </form>
    </>
  );
};

export default ShopAdminForm;
