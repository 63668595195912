import React, { useState, useEffect } from "react";

export const NewsAdminForm = (props) => {
  const initialFieldValues = {
    title: "",
    subHeading: "",
    bodyText: "",
    imageSrc: "",
    para2: "",
    para3: "",
    para4: "",
    para5: "",
    para6: "",
    para7: "",
    para8: "",
    para9: "",
    para10: "",
    downloadLink: "",
    downloadButtonText: "",
    downloadLink2: "",
    downloadButtonText2: "",
  };

  var [values, setValues] = useState(initialFieldValues);

  useEffect(() => {
    if (props.currentId === "")
      setValues({
        ...initialFieldValues,
      });
    else
      setValues({
        ...props.eventObjects[props.currentId],
      });
  }, [props.currentId, props.eventObjects]);

  const handleInputChange = (e) => {
    var { name, value } = e.target;
    setValues({
      ...values,
      [name]: value,
    });
  };

  const handleFormSubmit = (e) => {
    e.preventDefault();
    props.addOrEdit(values);
  };

  return (
    <>
      <form autoComplete="off" onSubmit={handleFormSubmit}>
        <input
          type="text"
          placeholder="Title of News Article"
          name="title"
          value={values.title}
          onChange={handleInputChange}
        />
        <input
          type="text"
          placeholder="Subheading of News Article"
          name="subHeading"
          value={values.subHeading}
          onChange={handleInputChange}
        />
        <textarea
          type="text"
          placeholder="Main Paragraph"
          name="bodyText"
          value={values.bodyText}
          onChange={handleInputChange}
        />
        <textarea
          type="text"
          placeholder="Paragraph 2"
          name="para2"
          value={values.para2}
          onChange={handleInputChange}
        />
        <textarea
          type="text"
          placeholder="Paragraph 3"
          name="para3"
          value={values.para3}
          onChange={handleInputChange}
        />
        <textarea
          type="text"
          placeholder="Paragraph 4"
          name="para4"
          value={values.para4}
          onChange={handleInputChange}
        />
        <textarea
          type="text"
          placeholder="Paragraph 5"
          name="para5"
          value={values.para5}
          onChange={handleInputChange}
        />
        <textarea
          type="text"
          placeholder="Paragraph 6"
          name="para6"
          value={values.para6}
          onChange={handleInputChange}
        />
        <textarea
          type="text"
          placeholder="Paragraph 7"
          name="para7"
          value={values.para7}
          onChange={handleInputChange}
        />
        <textarea
          type="text"
          placeholder="Paragraph 8"
          name="para8"
          value={values.para8}
          onChange={handleInputChange}
        />
        <textarea
          type="text"
          placeholder="Paragraph 9"
          name="para9"
          value={values.para9}
          onChange={handleInputChange}
        />
        <textarea
          type="text"
          placeholder="Paragraph 10"
          name="para10"
          value={values.para10}
          onChange={handleInputChange}
        />
        <textarea
          type="text"
          placeholder="Download Link"
          name="downloadLink"
          value={values.downloadLink}
          onChange={handleInputChange}
        />
        <textarea
          type="text"
          placeholder="Download Button Text"
          name="downloadButtonText"
          value={values.downloadButtonText}
          onChange={handleInputChange}
        />
        <textarea
          type="text"
          placeholder="Download Link 2"
          name="downloadLink2"
          value={values.downloadLink2}
          onChange={handleInputChange}
        />
        <textarea
          type="text"
          placeholder="Download Button Text 2"
          name="downloadButtonText2"
          value={values.downloadButtonText2}
          onChange={handleInputChange}
        />
        <input
          type="text"
          placeholder="Image Source"
          name="imageSrc"
          value={values.imageSrc}
          onChange={handleInputChange}
        />
        <input
          type="submit"
          value={props.currentId === "" ? "Save" : "Update"}
        />
      </form>
    </>
  );
};

export default NewsAdminForm;
