import React, { useState, useEffect } from "react";
import fireDb from "../../firebase";
import { Col, Row, Container } from "react-bootstrap";
import Testimonials from "../testimonials/Testimonial";

export const PatronSection = ({ title }) => {
  var [eventObjects, setEventObjects] = useState({});
  var [partnerSection, setPartnerSection] = useState({});

  useEffect(() => {
    fireDb
      .database()
      .ref()
      .child("Patrons")
      .on("value", (snapshot) => {
        if (snapshot.val() != null)
          setEventObjects({
            ...snapshot.val(),
          });
      });
  }, []);

  useEffect(() => {
    fireDb
      .database()
      .ref()
      .child("Members")
      .orderByChild("filterKey")
      .equalTo("Partner")
      .on("value", (snapshot) => {
        if (snapshot.val() != null)
          setPartnerSection({
            ...snapshot.val(),
          });
      });
  }, []);

  let urlCheck = window.location.pathname;
  return (
    <>
      <Container>
        {/* Changed to fix background colour issue */}
      {/* <Row className="partner-sections" style={{ marginRight: "0px" }}> */}
        <Row className="partner-sections">
          {title ?
          <div>
          <Col xs={3}></Col>
          <Col xs={9} className="partner-section-title">
            <h1>{title}</h1>
          </Col>
          <Col xs={3}></Col>
          </div>  : 
         null }
          

          {urlCheck === "/about/partners" ? (
            <>
              {Object.keys(partnerSection).map((id) => {
                return (
                  <>
                    <Testimonials
                      key={id}
                      image={partnerSection[id]?.imageSource}
                      author={partnerSection[id]?.name}
                      text={partnerSection[id]?.bodyText}
                      linkToMember={partnerSection[id]?.linkToMember}
                    />
                  </>
                );
              })}
            </>
          ) : (
            <>
              {Object.keys(eventObjects).map((id) => {
                return (
                  <>
                    <Testimonials
                      key={id}
                      image={eventObjects[id]?.imageSrc}
                      author={eventObjects[id]?.PatronName}
                      text={eventObjects[id]?.bodyText}
                    />
                  </>
                );
              })}
            </>
          )}

          {/* {Object.keys(eventObjects).map((id) => {
            return (
              <>
                <Testimonials
                  key={id}
                  image={eventObjects[id]?.imageSrc}
                  author={eventObjects[id]?.PatronName}
                  text={eventObjects[id]?.bodyText}
                />
              </>
            );
          })} */}
        </Row>
      </Container>
    </>
  );
};
