import React, { useState, useEffect } from "react";
import HeroBannerHeader from "../shared/HeroBannerHeader";
import { Col, Container, Row } from "react-bootstrap";
import { SectionTitle } from "../shared/SectionTitle";
import fireDb from "../firebase";

const Shop = () => {
  var [eventObjects, setEventObjects] = useState({});
  var [headingData, setHeadingData] = useState({});

  useEffect(() => {
    fireDb
      .database()
      .ref()
      .child("HeroHeader")
      .orderByChild("key")
      .equalTo("Shop")
      .on("value", (snapshot) => {
        if (snapshot.val() != null)
          setHeadingData({
            ...snapshot.val(),
          });
      });
  }, []);

  useEffect(() => {
    fireDb
      .database()
      .ref()
      .child("Shop")
      .on("value", (snapshot) => {
        if (snapshot.val() != null)
          setEventObjects({
            ...snapshot.val(),
          });
      });
  }, []);

  return (
    <div className="shop-container">
      {Object.keys(headingData).map((id) => {
        return (
          <HeroBannerHeader
            image={headingData[id].imageSource}
            title={headingData[id].title}
          />
        );
      })}
      <Container>
        {/* <SectionTitle title="Coming Soon" /> */}
        <br />
        <br />
        {Object.keys(headingData).map((id) => {
        return (
      <div className="mob-banner-title"><h1>Shop</h1></div>
      );
    })}
        <Row className="shop-items-containers" style={{ margin: "50px 0px" }}>
          {Object.keys(eventObjects).map((id) => {
            return (
              <>
                <Col
                  xs={12}
                  lg={6}
                  className="shop-items-containers-col"
                  key={id}
                >
                <h1>{eventObjects[id]?.nameOfProductLine}</h1>
                  <div className="shop-items-containers-col-box">
                      <img src={eventObjects[id]?.imageSource} alt="" />
                      <br />
                      <a href={eventObjects[id]?.linkToProduct} target="_blank">
                      <button>
                        Buy Now
                      </button>
                      </a>
                  </div>
                </Col>
              </>
            );
          })}
        </Row>
      </Container>
    </div>
  );
};

export default Shop;
