import React, { useCallback, useContext } from "react";
import { withRouter, Redirect } from "react-router-dom";
import fireDb from "./firebase";
import { AuthContext } from "./Auth.js";

const Login = ({ history }) => {
  const handleLogin = useCallback(
    async (event) => {
      event.preventDefault();
      const { email, password } = event.target.elements;
      try {
        await fireDb
          .auth()
          .signInWithEmailAndPassword(email.value, password.value);
        history.push("/admin");
      } catch (error) {
        alert(error);
      }
    },
    [history]
  );

  const { currentUser } = useContext(AuthContext);

  if (currentUser) {
    return <Redirect to="/admin" />;
  }

  return (
    <div>
      <div style={{ height: "200px" }}></div>
      <div style={{ marginBottom: "100px" }}>
        <h1>Log In</h1>
        <h3>Authorised users only</h3>
      </div>
      <form onSubmit={handleLogin} style={{}}>
        <label style={{ marginRight: "30px" }}>
          Email: {""} <input name="email" type="email" placeholder="Email" />
        </label>

        <label>
          Password: {""}
          <input name="password" type="password" placeholder="Password" />
        </label>
        <div style={{ marginTop: "40px" }}>
          <button
            style={{
              borderRadius: "25px",
              padding: "5px 10px",
              fontWeight: "800",
              fontSize: "1.2rem",
            }}
            type="submit"
          >
            Log in
          </button>
        </div>
      </form>
      <div style={{ height: "200px" }}></div>
    </div>
  );
};

export default withRouter(Login);
