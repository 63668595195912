import React, { useState, useEffect } from "react";
import { Row } from "react-bootstrap";
import { IndividualCompetitions } from "./sub-component/IndividualCompetitions";
import fireDb from "../../firebase";

export const Competitions = () => {
  var [eventObjects, setEventObjects] = useState({});

  useEffect(() => {
    fireDb
      .database()
      .ref()
      .child("Competitions")
      .on("value", (snapshot) => {
        if (snapshot.val() != null)
          setEventObjects({
            ...snapshot.val(),
          });
      });
  }, []);
  return (
    <>
      <Row>
        <div className="box-news">
          <h1>Competitions</h1>
        </div>
      </Row>
      <Row className="hp-competitions">
        {Object.keys(eventObjects).map((id) => {
          return (
            <IndividualCompetitions
              title={eventObjects[id]?.title}
              modalBodyText={eventObjects[id]?.bodyText}
              modalBodyText2={eventObjects[id]?.bodyText2}
              modalBodyText3={eventObjects[id]?.bodyText3}
              colNum="4"
              key={id}
              image={eventObjects[id]?.imageSrc}
              cardBodyText={eventObjects[id]?.bodyText}
            />
          );
        })}
      </Row>
    </>
  );
};
